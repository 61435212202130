
*{
    margin: 0;
    padding: 0;
}

.MuiFormControlLabel-label.Mui-disabled{
    color: #63656E !important;
}

.Mui-checked,
.MuiRadio-colorPrimary,
.MuiCheckbox-colorPrimary {
    color: #FF0000 !important;
}

.isBillingItemSelected{
    background-color: #FF0000;
}

.isBillingItemSelected *{
    color: #fff !important;
}

.MuiMultiSectionDigitalClock-root ul li.Mui-selected{
    background-color: #FF0000 !important;
}

.MuiMultiSectionDigitalClock-root ul.MuiMultiSectionDigitalClock-root{
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.MuiMultiSectionDigitalClock-root ul.MuiMultiSectionDigitalClock-root::-webkit-scrollbar{
    display: none;
}

.desktop_time_picker label{
    color: #161F30 !important;
}

.desktop_time_picker fieldset{
    border: none ;
}

.desktop_time_picker.error_time fieldset{
    border:1px solid #FF3D57 !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #FF3D57 !important;
}

.MuiFormHelperText-root{
    color: #FF3D57 !important;
}

[sidenavmode="mobile"],
[sidenavmode="full"],
[sidenavmode="compact"]{
    overflow: hidden !important;
}

[sidenavmode="compact"] .side-navigations-area{
    padding-bottom: 180px !important;
}

th{
    white-space: initial !important;
    overflow-wrap: anywhere !important;
}

.navigation-tab-1{
    padding-bottom: 100px !important;
}

.per-dialoge-full {
    width: 100%;
}